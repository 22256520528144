<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="3%">
            <h1 class="guest-app-child-heading">Guest App: Hotel Guest Experience Software</h1>
            <div *ngIf="mobile" style="align-items: center;">
                <img src="/assets/mobile-images/Guest-App-image.png" />
            </div>
            <div class="guest-app-child-text">A Platform for direct communications, real-time request tracking, and
                service bookings – all
                within a branded app. Showcase promotion and enhance upselling opportunities.
            </div>
            <div>
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img *ngIf="showTracking" [@fadeIn] class="live-tracking" src="/assets/images/Live-Tracking.png"
                alt="Live Tracking" />
            <img *ngIf="showVectors" [@vector1Animation] class="vector1" src="/assets/images/Guest-App-Vector1.png"
                alt="Guest App Vector 1" />
            <img class="guest-app" src="/assets/images/Guest-App-Image.png" alt="Guest App Image" />
            <img *ngIf="showVectors" [@vector2Animation] class="vector2" src="/assets/images/Guest-App-Vector2.png"
                alt="Guest App Vector 2" />
            <img *ngIf="showBookServices" [@fadeIn] class="book-services" src="/assets/images/Book Services.jpg"
                alt="Book Services" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            Why choose our Guest Experience Software?
        </h2>
        <div class="guest-app-child-text2">
            Our Guest App excels with intuitive design, seamless service booking management, and real-time guest
            communication, ensuring superior convenience and satisfaction for hoteliers and guests. 
        </div>
        <div *ngIf="!mobile">
            <button class="book-btn" (click)="openCalendlyPopup()">
                Book A Demo Today
            </button>
        </div>
        <div fxLayout="column" class="why-choose-content-container">
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Seamless Integration.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Seamless Integration
                    </h3>
                    <div class="why-choose-text">Works great on its own, no integrations required! Want to connect to
                        other apps? It's easy with our Open API.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Guest Satisfaction.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Enhance Guest Satisfaction
                    </h3>
                    <div class="why-choose-text">Guests can book housekeeping, spa treatments, and dining directly in
                        the app. No more front desk calls enhancing the overall customer experience</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Streamline Operations.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Streamline Operations
                    </h3>
                    <div class="why-choose-text">Guests can request services through the app, which then automatically
                        assigns tasks to the appropriate staff
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Insights.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Real-Time Insights
                    </h3>
                    <div class="why-choose-text">The app collects data on guest preferences, behaviours, and feedback,
                        providing valuable insights for improving hotel operations and services
                    </div>
                </div>
                <div *ngIf="mobile">
                    <button class="book-btn" (click)="openCalendlyPopup()">
                        Book A Demo Today
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">Features of our Guest Experience Platform</h2>
        <div fxLayout="row" class="guest-app-features-content">
            <div *ngIf="mobile" class="feature-image-container" fxLayout="column" fxLayoutAlign="center center">
                <div class="feature-image-circle">
                    <img class="feature-mobile-image" src="assets/mobile-images/Feature-image.png" alt="Feature Image">
                </div>
            </div>
            <div fxLayout="column" class="left-side-points" fxLayoutGap="5%">
                <div fxLayout="column">
                    <h3 class="features-header">
                        Upselling Opportunities
                    </h3>
                    <div class="features-text">
                        Offer guests tempting deals and promotions (spa treatments, in-room dining etc.) right on their
                        phones. This drives guest spending and increases your hotel's revenue. 
                    </div>
                </div>
                <div fxLayout="column">
                    <h3 class="features-header">
                        Real-Time Communication
                    </h3>
                    <div class="features-text">
                        Guests can communicate directly with the hotel staff for requests, bookings, and complaints,
                        ensuring timely responses
                    </div>
                </div>
                <div fxLayout="column">
                    <h3 class="features-header">
                        Automated Feedback Collection
                    </h3>
                    <div class="features-text">
                        Empower guests to provide in-stay feedback for all services through surveys in the app.
                        Real-time feedback lets you address concerns instantly, ensuring a continuous improvement in
                        customer experience.
                    </div>
                </div>
            </div>
            <div *ngIf="!mobile" fxFlex="48%" class="feature-image-container" fxLayout="column"
                fxLayoutAlign="center center">
                <div class="feature-image-circle">
                    <img class="feature-mobile-image" src="assets/images/Feature-image.png" alt="Feature Image">
                </div>
            </div>
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading3">
            How our Hotel Guest Experience Software works?
        </h2>
        <div class="guest-app-child-text2">
            Our Guest Experience software simplifies service bookings, tracks preferences, offers personalization, and
            provides real-time support, ensuring total satisfaction and efficiency
        </div>
        <div>
            <video #guestVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
                <source src="assets/videos/guest-app.mp4" type="video/mp4" />
            </video>
        </div>
        <div>
            <button class="book-btn" (click)="openCalendlyPopup()">
                Book A Demo Today
            </button>
        </div>
    </div>
    <div fxLayout="row" class="faq-container width100" fxLayoutAlign="space-between" *ngIf="!mobile">
        <div>
            <h2 class="faq-header">FAQ’S</h2>
        </div>
        <div fxLayout="column" class="faq-content">
            <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
                <div class="faq-question">
                    {{ faq.question }}
                    <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
                </div>
                <div class="faq-answer" *ngIf="faq.open">
                    {{ faq.answer }}
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="column" class="faq-container width100" fxLayoutAlign="space-between" *ngIf="mobile">
        <div>
            <h2 class="faq-header">FAQ’S</h2>
        </div>
        <div fxLayout="column" class="faq-content">
            <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
                <div class="faq-question">
                    {{ faq.question }}
                    <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
                </div>
                <div class="faq-answer" *ngIf="faq.open">
                    {{ faq.answer }}
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>