import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from 'src/environments/environment';
declare const Calendly: any;

@Component({
  selector: 'app-guest-app',
  templateUrl: './guest-app.component.html',
  styleUrls: ['./guest-app.component.css'],
  animations: [
    trigger('vector1Animation', [
      transition(':enter', [
        style({ transform: 'translate(165px, 276px)', opacity: 0 }),
        animate('0.3s ease-out', style({ transform: 'translate(175px, 276px)', opacity: 1 }))
      ])
    ]),
    trigger('vector2Animation', [
      transition(':enter', [
        style({ transform: 'translate(409px, 70px)', opacity: 0 }),
        animate('0.3s ease-out', style({ transform: 'translate(419px, 70px)', opacity: 1 }))
      ])
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.8s ease-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class GuestAppComponent {
  @ViewChild("guestVideo", { static: false })
  guestVideo: ElementRef;
  mobile: boolean = false;
  isGuestVideoIntoView: boolean;
  showVectors: boolean = false;
  showTracking: boolean = false;
  showBookServices: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  poster = '../../../assets/images/thumbnail.png';
  poster1 = '../../../assets/images/guest-app.png';
  isMuted: boolean = true;

  faqs = [
    {
      question: 'What is hotel guest experience software?',
      answer: 'Hotel guest experience software streamlines hotel operations (booking, check-in, etc.) and lets guests control their stay (room service, feedback). It helps you keep guests happy, coming back for more, and leaving great reviews.',
      open: false
    },
    {
      question: 'How will the guest experience platform improve our hotels operations?',
      answer: 'A guest experience platform will significantly enhance your operations by automating booking processes, streamlining check-ins, and improving room service efficiency. It allows for personalized guest interactions and real-time feedback management, ensuring quick issue resolution and continuous service improvements. Integration with your existing systems will also optimize data flow and provide valuable insights into guest behavior, helping us make informed decisions to boost guest satisfaction and drive profitability.',
      open: false
    },
    {
      question: 'Is the guest experience app user-friendly for both staff and guests?',
      answer: 'Yes',
      open: false
    },
    {
      question: 'Can the hotel feedback app be customized to fit our specific needs?',
      answer: 'Yes',
      open: false
    },
    {
      question: 'How secure is the data collected through your guest experience software?',
      answer: 'Completely secure! The security of data collected through guest experience software is crucial and typically ensured through various measures such as encryption protocols for data transmission, secure storage practices, access controls, and compliance with data protection regulations (like GDPR or CCPA).',
      open: false
    }
  ];

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.guestVideo) {
      this.isMuted = true;
      const rect = this.guestVideo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isGuestVideoIntoView = topShown && bottomShown;
      if (this.isGuestVideoIntoView == true) {
        this.guestVideo.nativeElement.play();
      } else {
        this.guestVideo.nativeElement.pause();
      }
    }
  }

  constructor() { }
  ngOnInit() {
    this.checkScreenWidth();
    this.animateImages();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  toggleVideo() {
    this.isMuted = false;
  }

  getVideo(video) {
    video.play();
  }

  stopVideo(video) {
    video.pause();
  }

  private animateImages() {
    setTimeout(() => {
      this.showVectors = true;
      setTimeout(() => {
        this.showTracking = true;
        this.showBookServices = true;
      }, 300);
    }, 300);
  }

}
