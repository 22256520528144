<app-header></app-header>
<div class="container">
    <div mat-dialog-content fxLayout="column" fxLayoutAlign="center center" class="content" fxLayoutGap="2%">
        <div class="logo">
            <img src="assets/images/logo.svg" />
        </div>
        <div>
            <img src="assets/images/verified.png">
        </div>
        <div class="heading">Thank You. You're All Set!</div>
        <div class="message">Your demo is confirmed. We can't wait to show you how our solution can help your hotel thrive.<br>Check your inbox for the details</div>
        <div>
            <button mat-button class="done-btn" (click)="done()">Done</button>
          </div>
    </div>
</div>

<app-footer></app-footer>