import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendly',
  templateUrl: './calendly.component.html',
  styleUrls: ['./calendly.component.css']
})
export class CalendlyComponent {
  constructor(private router: Router) { }
  ngOnInit() { }
  done() {
    this.router.navigateByUrl('');
    window.scrollTo(0, 0);
  }

}
