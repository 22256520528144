import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FreeTrialComponent } from './pages/free-trial/free-trial.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { PlansComponent } from './pages/plans/plans.component';
import { FeaturesPageComponent } from './pages/features-page/features-page.component';
import { PartnerProgramComponent } from './pages/partner-program/partner-program.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { ExperienceDetailComponent } from './pages/experience-detail/experience-detail.component';
import { ReserveComponent } from './pages/reserve/reserve.component';
import { GuestAppComponent } from './pages/guest-app/guest-app.component';
import { ClioComponent } from './pages/clio/clio.component';
import { TaskAppComponent } from './pages/task-app/task-app.component';
import { CalendlyComponent } from './pages/calendly/calendly.component';
import { Blog1Component } from './pages/blog1/blog1.component';
import { Blog2Component } from './pages/blog2/blog2.component';
import { Blog3Component } from './pages/blog3/blog3.component';
import { Blog4Component } from './pages/blog4/blog4.component';
import { Blog5Component } from './pages/blog5/blog5.component';
import { Blog6Component } from './pages/blog6/blog6.component';
import { Blog7Component } from './pages/blog7/blog7.component';
import { Blog8Component } from './pages/blog8/blog8.component';
import { Blog9Component } from './pages/blog9/blog9.component';
import { Blog10Component } from './pages/blog10/blog10.component';
import { Blog11Component } from './pages/blog11/blog11.component';
import { Blog12Component } from './pages/blog12/blog12.component';
import { Blog13Component } from './pages/blog13/blog13.component';




const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    component: HomePageComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/",
      hreflangs: [
        { lang: 'en-us', path: '/' },
        { lang: 'x-default', path: '/' },
        { lang: 'en', path: '/' }

      ]
    }
  },
  {
    path: 'free-trial',
    component: FreeTrialComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/free-trial",
      hreflangs: [
        { lang: 'en-us', path: '/free-trial' },
        { lang: 'x-default', path: '/free-trial' },
        { lang: 'en', path: '/free-trial' },
      ]
    }
  },
  {
    path: 'features',
    component: FeaturesPageComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GGoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'features/hotel-guest-experience-software',
    component: GuestAppComponent,
    data: {
      title: "Guest App: Hotel Guest Experience Software | GoFriday",
      description: "Enhance your hospitality with our hotel guest experience software. Boost guest satisfaction and streamline operations effortlessly. Contact us now!",
      ogTitle: "Guest App: Hotel Guest Experience Software | GoFriday",
      ogDescription: "Enhance your hospitality with our hotel guest experience software. Boost guest satisfaction and streamline operations effortlessly. Contact us now!",
      canonicalUrl: "/features/hotel-guest-experience-software",
      hreflangs: [
        { lang: 'en-us', path: '/features/hotel-guest-experience-software' },
        { lang: 'x-default', path: '/features/hotel-guest-experience-software' },
        { lang: 'en', path: '/features/hotel-guest-experience-software' }
      ]
    }
  },
  {
    path: 'features/clio-hotel-business-intelligence-software',
    component: ClioComponent,
    data: {
      title: "Clio: Best Hotel Business Intelligence Software | GoFriday",
      description: "Optimize operations and boost revenue with hotel business intelligence software. Gain insights, track performance, and make data-driven decisions effortlessly.",
      ogTitle: "Clio: Best Hotel Business Intelligence Software | GoFriday",
      ogDescription: "Optimize operations and boost revenue with hotel business intelligence software. Gain insights, track performance, and make data-driven decisions effortlessly.",
      canonicalUrl: "/features/clio-hotel-business-intelligence-software",
      hreflangs: [
        { lang: 'en-us', path: '/features/clio-hotel-business-intelligence-software' },
        { lang: 'x-default', path: '/features/clio-hotel-business-intelligence-software' },
        { lang: 'en', path: '/features/clio-hotel-business-intelligence-software' }
      ]
    }
  },
  {
    path: 'features/hotel-task-management-software',
    component: TaskAppComponent,
    data: {
      title: "Task App: Hotel Task Management Software | GoFriday",
      description: "Efficiently manage hotel tasks with our hotel task management software. Simplify guest requests and automate tasks with our powerful app. Contact us now!",
      ogTitle: "Task App: Hotel Task Management Software | GoFriday",
      ogDescription: "Efficiently manage hotel tasks with our hotel task management software. Simplify guest requests and automate tasks with our powerful app. Contact us now!",
      canonicalUrl: "/features/hotel-task-management-software",
      hreflangs: [
        { lang: 'en-us', path: '/features/hotel-task-management-software' },
        { lang: 'x-default', path: '/features/hotel-task-management-software' },
        { lang: 'en', path: '/features/hotel-task-management-software' }
      ]
    }
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: {
      title: "About us - Leading Hotel Management Software Solutions",
      description: "Learn how GoFriday empowers hotels to enhance guest experience and streamline operations with innovative AI-driven hospitality management software. Discover our mission, vision, and commitment to transforming the hotel industry.",
      ogTitle: "About us - Leading Hotel Management Software Solutions",
      ogDescription: "Learn how GoFriday empowers hotels to enhance guest experience and streamline operations with innovative AI-driven hospitality management software. Discover our mission, vision, and commitment to transforming the hotel industry.",
      canonicalUrl: "/about-us",
      hreflangs: [
        { lang: 'en-us', path: '/about-us' },
        { lang: 'x-default', path: '/about-us' },
        { lang: 'en', path: '/about-us' }
      ]
    }
  },
  {
    path: 'plans',
    component: PlansComponent,
    data: {
      title: "Flexible Hospitality Management Software Plans",
      description: "Explore GoFriday’s tailored plans to enhance hotel operations and guest experience. Whether you're a small boutique or a large chain, find the perfect hospitality management software to boost revenue and streamline processes.",
      ogTitle: "Flexible Hospitality Management Software Plans",
      ogDescription: "Explore GoFriday’s tailored plans to enhance hotel operations and guest experience. Whether you're a small boutique or a large chain, find the perfect hospitality management software to boost revenue and streamline processes.",
      canonicalUrl: "/plans",
      hreflangs: [
        { lang: 'en-us', path: '/plans' },
        { lang: 'x-default', path: '/plans' },
        { lang: 'en', path: '/plans' }
      ]
    }
  },
  {
    path: 'blog',
    component: BlogPageComponent,
    data: {
      title: "Blog - Hospitality Insights & Hotel Management Tips",
      description: "Stay updated with the latest trends in hotel management, guest experience, and hospitality technology. Explore expert tips and industry insights from GoFriday to help your hotel thrive.",
      ogTitle: "Blog - Hospitality Insights & Hotel Management Tips",
      ogDescription: "Stay updated with the latest trends in hotel management, guest experience, and hospitality technology. Explore expert tips and industry insights from GoFriday to help your hotel thrive.",
      canonicalUrl: "/blog",
      hreflangs: [
        { lang: 'en-us', path: '/blog' },
        { lang: 'x-default', path: '/blog' },
        { lang: 'en', path: '/blog' }
      ]
    }
  },
  {
    path: 'blog/hicsa',
    component: Blog1Component,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/blog/hicsa",
      hreflangs: [
        { lang: 'en-us', path: '/blog/hicsa' },
        { lang: 'x-default', path: '/blog/hicsa' },
        { lang: 'en', path: '/blog/hicsa' }
      ]
    }
  },
  {
    path: 'blog/guest-centric-revolution',
    component: Blog2Component,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/blog/guest-centric-revolution",
      hreflangs: [
        { lang: 'en-us', path: '/blog/guest-centric-revolution' },
        { lang: 'x-default', path: '/blog/guest-centric-revolution' },
        { lang: 'en', path: '/blog/guest-centric-revolution' }
      ]
    }
  },
  {
    path: 'blog/what-is-a-hotel-crm',
    component: Blog3Component,
    data: {
      title: "What Is A Hotel CRM & How It Maximizes Hotel Success?",
      description: "Discover how hotel CRM systems can enhance guest experience, improve operations, and increase revenue in the hospitality industry. Read Now!",
      ogTitle: "What Is A Hotel CRM & How It Maximizes Hotel Success?",
      ogDescription: "Discover how hotel CRM systems can enhance guest experience, improve operations, and increase revenue in the hospitality industry. Read Now!",
      canonicalUrl: "/blog/what-is-a-hotel-crm",
      hreflangs: [
        { lang: 'en-us', path: '/blog/what-is-a-hotel-crm' },
        { lang: 'x-default', path: '/blog/what-is-a-hotel-crm' },
        { lang: 'en', path: '/blog/what-is-a-hotel-crm' }
      ]
    }
  },
  {
    path: 'blog/features-of-a-crm-system-for-hotels',
    component: Blog4Component,
    data: {
      title: "9 Core Features of a CRM System for Hotels",
      description: "Explore the essential features of a CRM system that drive better customer relationships, streamline operations, and boost business efficiency. Read Now!",
      ogTitle: "9 Core Features of a CRM System for Hotels",
      ogDescription: "Explore the essential features of a CRM system that drive better customer relationships, streamline operations, and boost business efficiency. Read Now!",
      canonicalUrl: "/blog/features-of-a-crm-system-for-hotels",
      hreflangs: [
        { lang: 'en-us', path: '/blog/features-of-a-crm-system-for-hotels' },
        { lang: 'x-default', path: '/blog/features-of-a-crm-system-for-hotels' },
        { lang: 'en', path: '/blog/features-of-a-crm-system-for-hotels' }
      ]
    }
  },
  {
    path: 'blog/automated-hotel',
    component: Blog5Component,
    data: {
      title: "Automated Hotel: Benefits, Implementation & Risks",
      description: "Discover the benefits, implementation strategies, and risks of hotel automation. Learn how to enhance guest experience & streamline operations effectively",
      ogTitle: "Automated Hotel: Benefits, Implementation & Risks",
      ogDescription: "Discover the benefits, implementation strategies, and risks of hotel automation. Learn how to enhance guest experience & streamline operations effectively",
      canonicalUrl: "/blog/automated-hotel",
      hreflangs: [
        { lang: 'en-us', path: '/blog/automated-hotel' },
        { lang: 'x-default', path: '/blog/automated-hotel' },
        { lang: 'en', path: '/blog/automated-hotel' }
      ]
    }
  },
  {
    path: 'blog/hotel-profit-margin',
    component: Blog6Component,
    data: {
      title: "What is a Hotel Profit Margin? Strategies to Boost in 2024",
      description: "Learn what hotel profit margins are and discover effective strategies to boost your hotel’s profitability in 2024. Maximize revenue & control costs efficiently",
      ogTitle: "What is a Hotel Profit Margin? Strategies to Boost in 2024",
      ogDescription: "Learn what hotel profit margins are and discover effective strategies to boost your hotel’s profitability in 2024. Maximize revenue & control costs efficiently",
      canonicalUrl: "/blog/hotel-profit-margin",
      hreflangs: [
        { lang: 'en-us', path: '/blog/hotel-profit-margin' },
        { lang: 'x-default', path: '/blog/hotel-profit-margin' },
        { lang: 'en', path: '/blog/hotel-profit-margin' }
      ]
    }
  },
  {
    path: 'blog/what-is-hospitality-management',
    component: Blog7Component,
    data: {
      title: "What Is Hospitality Management: Careers, Skills, and More",
      description: "Explore what is hospitality management, from its definition and importance to career opportunities, essential skills, and industry trends. Read now!",
      ogTitle: "What Is Hospitality Management: Careers, Skills, and More",
      ogDescription: "Explore what is hospitality management, from its definition and importance to career opportunities, essential skills, and industry trends. Read now!",
      canonicalUrl: "/blog/what-is-hospitality-management",
      hreflangs: [
        { lang: 'en-us', path: '/blog/what-is-hospitality-management' },
        { lang: 'x-default', path: '/blog/what-is-hospitality-management' },
        { lang: 'en', path: '/blog/what-is-hospitality-management' }
      ]
    }
  },
  {
    path: 'blog/how-to-improve-hotel-revenue',
    component: Blog8Component,
    data: {
      title: "13 Smart Strategies On How to Improve Hotel Revenue",
      description: "Discover actionable strategies on how to improve hotel revenue, from optimizing pricing and marketing to improving guest experience and leveraging technology.",
      ogTitle: "13 Smart Strategies On How to Improve Hotel Revenue",
      ogDescription: "Discover actionable strategies on how to improve hotel revenue, from optimizing pricing and marketing to improving guest experience and leveraging technology.",
      canonicalUrl: "/blog/how-to-improve-hotel-revenue",
      hreflangs: [
        { lang: 'en-us', path: '/blog/how-to-improve-hotel-revenue' },
        { lang: 'x-default', path: '/blog/how-to-improve-hotel-revenue' },
        { lang: 'en', path: '/blog/how-to-improve-hotel-revenue' }
      ]
    }
  },
  {
    path: 'blog/what-is-a-hotel-cancellation-policy',
    component: Blog9Component,
    data: {
      title: "Hotel Cancellation Policy: Everything You Need to Know",
      description: "Understand hotel cancellation policies, key terms, and tips to avoid fees. Get insights on flexible bookings, refunds, and exceptions. Read now!",
      ogTitle: "Hotel Cancellation Policy: Everything You Need to Know",
      ogDescription: "Understand hotel cancellation policies, key terms, and tips to avoid fees. Get insights on flexible bookings, refunds, and exceptions. Read now!",
      canonicalUrl: "/blog/what-is-a-hotel-cancellation-policy",
      hreflangs: [
        { lang: 'en-us', path: '/blog/what-is-a-hotel-cancellation-policy' },
        { lang: 'x-default', path: '/blog/what-is-a-hotel-cancellation-policy' },
        { lang: 'en', path: '/blog/what-is-a-hotel-cancellation-policy' }
      ]
    }
  },
  {
    path: 'blog/benefits-of-crm-in-hotel-industry',
    component: Blog10Component,
    data: {
      title: "6 Benefits Of CRM In Hotel Industry",
      description: "Discover how CRM software boosts efficiency and guest satisfaction in the hotel industry. Learn about features, uses, and tips for choosing the right CRM.",
      ogTitle: "6 Benefits Of CRM In Hotel Industry",
      ogDescription: "Discover how CRM software boosts efficiency and guest satisfaction in the hotel industry. Learn about features, uses, and tips for choosing the right CRM.",
      canonicalUrl: "/blog/benefits-of-crm-in-hotel-industry",
      hreflangs: [
        { lang: 'en-us', path: '/blog/benefits-of-crm-in-hotel-industry' },
        { lang: 'x-default', path: '/blog/benefits-of-crm-in-hotel-industry' },
        { lang: 'en', path: '/blog/benefits-of-crm-in-hotel-industry' }
      ]
    }
  },
  {
    path: 'blog/how-to-travel-cheap',
    component: Blog11Component,
    data: {
      title: "How to Travel Cheaper: Insider Tips for Affordable Adventures",
      description: "Discover insider tips on how to travel cheaper and make the most of your adventures without breaking the bank. Start planning your affordable getaway today!",
      ogTitle: "How to Travel Cheaper: Insider Tips for Affordable Adventures",
      ogDescription: "Discover insider tips on how to travel cheaper and make the most of your adventures without breaking the bank. Start planning your affordable getaway today!",
      canonicalUrl: "/blog/how-to-travel-cheap",
      hreflangs: [
        { lang: 'en-us', path: '/blog/how-to-travel-cheap' },
        { lang: 'x-default', path: '/blog/how-to-travel-cheap' },
        { lang: 'en', path: '/blog/how-to-travel-cheap' }
      ]
    }
  },
  {
    path: 'blog/difference-between-hotel-and-motel',
    component: Blog12Component,
    data: {
      title: "What's The Difference Between Hotel And Motel?",
      description: "Discover the key differences between hotels and motels, from amenities and pricing to ideal uses for your travel needs. Find the perfect stay!",
      ogTitle: "What's The Difference Between Hotel And Motel?",
      ogDescription: "Discover the key differences between hotels and motels, from amenities and pricing to ideal uses for your travel needs. Find the perfect stay!",
      canonicalUrl: "/blog/difference-between-hotel-and-motel",
      hreflangs: [
        { lang: 'en-us', path: '/blog/difference-between-hotel-and-motel' },
        { lang: 'x-default', path: '/blog/difference-between-hotel-and-motel' },
        { lang: 'en', path: '/blog/difference-between-hotel-and-motel' }
      ]
    }
  },
  {
    path: 'blog/how-to-start-a-travel-agency',
    component: Blog13Component,
    data: {
      title: "Step-by-Step Guide: How to Start a Travel Agency",
      description: "Learn how to start a travel agency with our step-by-step guide, covering everything from planning and licensing to marketing and customer service",
      ogTitle: "Step-by-Step Guide: How to Start a Travel Agency",
      ogDescription: "Learn how to start a travel agency with our step-by-step guide, covering everything from planning and licensing to marketing and customer service",
      canonicalUrl: "/blog/how-to-start-a-travel-agency",
      hreflangs: [
        { lang: 'en-us', path: '/blog/how-to-start-a-travel-agency' },
        { lang: 'x-default', path: '/blog/how-to-start-a-travel-agency' },
        { lang: 'en', path: '/blog/how-to-start-a-travel-agency' }
      ]
    }
  },
  {
    path: 'partner-program',
    component: PartnerProgramComponent,
    data: {
      title: "Partner Program - Join Us & Enhance Hospitality Solutions",
      description: "Explore the GoFriday Partner Program and discover opportunities to collaborate, expand your offerings, and drive growth. Partner with us to deliver cutting-edge hotel management solutions and enhance guest experiences.",
      ogTitle: "Partner Program - Join Us & Enhance Hospitality Solutions",
      ogDescription: "Explore the GoFriday Partner Program and discover opportunities to collaborate, expand your offerings, and drive growth. Partner with us to deliver cutting-edge hotel management solutions and enhance guest experiences.",
      canonicalUrl: "/partner-program",
      hreflangs: [
        { lang: 'en-us', path: '/partnerprogram' },
        { lang: 'x-default', path: '/partnerprogram' },
        { lang: 'en', path: '/partnerprogram' }
      ]
    }
  },
  {
    path: 'experiences',
    component: ExperienceComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/experiences",
      hreflangs: [
        { lang: 'en-us', path: '/experiences' },
        { lang: 'x-default', path: '/experiences' },
        { lang: 'en', path: '/experiences' }
      ]
    }
  },
  {
    path: 'experience-details',
    component: ExperienceDetailComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/experience-details",
      hreflangs: [
        { lang: 'en-us', path: '/experience-details' },
        { lang: 'x-default', path: '/experience-details' },
        { lang: 'en', path: '/experience-details' }
      ]
    }
  },
  {
    path: 'reserve',
    component: ReserveComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/reserve",
      hreflangs: [
        { lang: 'en-us', path: '/reserve' },
        { lang: 'x-default', path: '/reserve' },
        { lang: 'en', path: '/reserve' }
      ]
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GGoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/privacy-policy",
      hreflangs: [
        { lang: 'en-us', path: '/privacy-policy' },
        { lang: 'x-default', path: '/privacy-policy' },
        { lang: 'en', path: '/privacy-policy' }
      ]
    }
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GGoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/terms-conditions",
      hreflangs: [
        { lang: 'en-us', path: '/terms-conditions' },
        { lang: 'x-default', path: '/terms-conditions' },
        { lang: 'en', path: '/terms-conditions' }
      ]
    }
  },
  {
    path: 'calendly-thank-you',
    component: CalendlyComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/terms-conditions",
      hreflangs: [
        { lang: 'en-us', path: '/calendly-thank-you' },
        { lang: 'x-default', path: '/calendly-thank-you' },
        { lang: 'en', path: '/calendly-thank-you' }
      ]
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }