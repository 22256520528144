import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private title: Title,
    private meta: Meta,

  ) { }

  updateTitle(title: string) {
    if (!!title) {
      this.title.setTitle(title);
    }
  }

  updateDescription(description: string) {
    if (!!description) {
      this.meta.updateTag({ name: 'description', content: description });
    }
  }

  updateOgTitle(ogtitle: string) {
    if (!!ogtitle) {
      this.meta.updateTag({ property: 'og:title', content: ogtitle });
    }
  }

  updateOgDescription(ogdescription: string) {
    if (!!ogdescription) {
      this.meta.updateTag({ property: 'og:description', content: ogdescription });
    }
  }

  // updateOgUrl(ogurl: string) {
  //   if (!!ogurl) {
  //     this.meta.updateTag({ property: 'og:url', content: ogurl });
  //   }
  // }

  updateCanonicalUrl(baseUrl: string, url: string) {
    const link: HTMLLinkElement = document.querySelector("link[rel='canonical']") || document.createElement('link');
    link.setAttribute('rel', 'canonical');
    const fullUrl = `${baseUrl}${url.startsWith('/') ? url : '/' + url}`;
    link.setAttribute('href', fullUrl);

    if (!document.head.contains(link)) {
      document.head.appendChild(link);
    }
  }

  updateHreflangTags(hreflangs: { hreflang: string; href: string }[], baseUrl: string) {
    const existingHreflangs = document.querySelectorAll("link[rel='alternate']");
    existingHreflangs.forEach(tag => tag.remove());
    if (hreflangs && hreflangs.length > 0) {
      const canonicalLink = document.querySelector("link[rel='canonical']");
      hreflangs.forEach(({ hreflang, href }) => {
        const fullUrl = `${baseUrl}${href.startsWith('/') ? href : '/' + href}`;
        const link: HTMLLinkElement = document.createElement('link');
        link.setAttribute('rel', 'alternate');
        link.setAttribute('hreflang', hreflang);
        link.setAttribute('href', fullUrl);

        if (canonicalLink) {
          canonicalLink.insertAdjacentElement('afterend', link);
        } else {
          document.head.appendChild(link);
        }
      });
    }
  }

}