import { Component } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { MetaService } from './services/meta.service';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'gofryday-website';
  public websiteUrl = `${environment.WEBSITE_URL}`;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService,
  ) { }

  ngOnInit() {
    console.log('AppComponent initialized');
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        tap(({ title, description, ogTitle, ogDescription, canonicalUrl, hreflangs }: Data) => {
          this.metaService.updateTitle(title);
          this.metaService.updateDescription(description);
          this.metaService.updateOgTitle(ogTitle);
          this.metaService.updateOgDescription(ogDescription);
          const url = canonicalUrl || this.router.url;
          this.metaService.updateCanonicalUrl(this.websiteUrl, url);
          if (hreflangs && Array.isArray(hreflangs)) {
            this.metaService.updateHreflangTags(hreflangs.map(({ lang, path }) => ({
              hreflang: lang,
              href: path
            })), this.websiteUrl);
          }
        })

      )
      .subscribe();
  }

}
