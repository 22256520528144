import { Component, HostListener } from '@angular/core';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/shared/color-object';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactUsComponent } from '../contact-us/contact-us.component';
declare const Calendly: any;

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent {

  mobile: boolean = false;
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  freePlan: string[] = [];
  basicPlan: string[] = [];
  standardPlan: string[] = [];
  professionalPlan: string[] = [];
  advancedPlan: string[] = [];
  enterprisePlan: string[] = [];
  contactPopupSetTimeOut;
  public calendlyUrl = environment.CALENDLY_URL;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute
        
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
    let popupScreen = window.sessionStorage.getItem('planPopup');
    if (!popupScreen) {
      this.showContactPopup();
    }
    this.freePlan = ['Upto 2 Users', 'Upto 20 Rooms', 'In Room Dining'];
    this.basicPlan = ['Upto 10 Users', 'Upto 50 Rooms', 'HouseKeeping', 'In Room Dining'];
    // this.standardPlan = ['All the features of Basic', '10 users', 'Upto 150 Rooms', 'User Management', 'Spa Management', 'Transportation Management', 'Conference Room Management', 'Guest Rating Management', 'Offer Management'];
    this.professionalPlan = ['Unlimited Users', 'Unlimited Rooms', 'All the Modules'];
    // this.advancedPlan = ['All the features of Professional', '20 Users', 'Upto 300 Rooms'];
    this.enterprisePlan = ['Unlimited Users', 'Unlimited Rooms', 'All the Modules', 'White-Labelling', 'Custom Pricing',];
  }

  ngOnDestroy() {
    clearTimeout(this.contactPopupSetTimeOut);
  }

  showContactPopup() {
    this.contactPopupSetTimeOut = setTimeout(() => {
      let popupScreen = window.sessionStorage.getItem('planPopup');
      if (!popupScreen) {
        window.sessionStorage.setItem('planPopup', 'true');
        const dialogRef = this.dialog.open(ContactUsComponent, {
          width: '80%',
          height: '81vh',
          position: { top: '14vh' },
          disableClose: true,
          autoFocus: false
        });
      }
    }, 5000);
  }

  ngAfterViewInit() {
    this.checkScreenWidth();
    // @ViewChild("container")container: ElementRef
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private scrollToSection(sectionId: string) {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  scrollLeft(event) {
    let scrollValue = event.scrollLeft - 300;
    event.scrollTo({
      left: scrollValue,
      behavior: 'smooth'
    });
  }

  scrollRight(event) {
    let scrollValue = event.scrollLeft + 300;
    event.scrollTo({
      left: scrollValue,
      behavior: 'smooth'
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  selectFreeTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    this.disabled = true;
    let obj = {
      "NAME": this.contactUsForm.value.name,
      "EMAIL": this.contactUsForm.value.email,
      "MESSAGE": this.contactUsForm.value.message,
      "TNC": true
    }
    this.hotelService.contactUs(obj).subscribe({
      next: (data) => {
        this.disabled = false;
        if (!!data?.object?.ID) {
          const dialogRef = this.dialog.open(PopupComponent, {
            width: '50%',
            data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' }
          });
        } else {
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        }
        this.contactUsForm.reset();
      },
      error: (e) => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

}