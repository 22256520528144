<app-header></app-header>
<div fxLayout="column" class="blogContainer width100">
    <div fxLayout="row">
        <div class="back-arrow" (click)="backButton()">
            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
        </div>
        <h1 class="blog-header">
            Hotel Cancellation Policy: Everything You Need to Know
        </h1>
    </div>
    <mat-divider class="material-devider"></mat-divider>
    <div fxLayout="row" class="sub-content" fxLayoutAlign="space-evenly">
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">Janhavi Srivastava</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">16 Sept 2024</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <iconify-icon icon="material-symbols-light:share-outline" class="share-icon"></iconify-icon>
            <a class="blog-desc" style="cursor: pointer;" (click)="viewLinkedIn()">LinkedIn</a>
        </div>
    </div>
</div>
<div class="infoContainer width100" fxLayout="column" fxLayoutAlign=" center">
    <div class="Hicsa-prgm-container" *ngIf="!mobile">
        <img src="assets/images/Cancellation Policy.jpg" class="prgm-image">
    </div>
    <div class="Hicsa-prgm-container" *ngIf="mobile">
        <img src="assets/mobile-images/Cancellation Policy.png">
    </div>
    <div class="sticky-overview" fxFlex="35" [ngClass]="{ 'sticky-overview-active': showStickyOverview }">
        <div class="overview-header">Overview</div>
        <ul class="overview-list">
            <li><span (click)="viewCancellationPolicy()" [class.active]="activeSection === 'Cancellation-Policy'">What
                    is a Hotel Cancellation
                    Policy?</span></li>
            <li><span (click)="viewTypesofCancellationPolicies()"
                    [class.active]="activeSection === 'Types-Of-Cancellation-Policy'">Types of Hotel Cancellation
                    Policies</span></li>
            <li><span (click)="viewKeyTermsInCancellationPolicy()"
                    [class.active]="activeSection === 'Key-Terms-In-Cancellation-Policy'">Key Terms in Hotel
                    Cancellation Policies</span></li>
            <li><span (click)="viewBookingCancellationPolicy()"
                    [class.active]="activeSection === 'Booking-Cancellation-Policy'">The Importance of Having a
                    <br> Clear Booking Cancellation Policy</span></li>
            <li><span (click)="viewHotelRefundPolicy()" [class.active]="activeSection === 'Hotel-Refund-Policy'">Hotel
                    Refund Policy Process:<br>
                    What to Expect?</span>
            </li>
            <li><span (click)="viewConclusion()" [class.active]="activeSection === 'Conclusion'">Conclusion</span></li>
        </ul>
        <div fxLayout="column" class="sticky-content" fxLayoutAlign=" center">
            <div>
                <img src="assets/images/Gofryday-logo.png">
            </div>
            <div>
                <p class="sticky-text">Delight your guests <br> with AI-powered <br> experiences</p>
            </div>
            <div>
                <button class="demo-btn" (click)="openCalendlyPopup()">
                    Book a Demo
                </button>
            </div>
        </div>
    </div>
    <div class="right-side-content" fxLayout="column">
        <div fxLayout="column" class="info-content-revolution" fxLayoutGap="2%" fxFlex="65%">
            <div class="info-text">However simple it may sound, booking a hotel can be harder than one imagines. Plans
                change, and now you find yourself scanning through the reservation details if any penalties are
                associated with your cancellation. Knowing what a hotel’s cancellation and Hotel refund policy is and
                working around it can save you time and money and squeeze out preventable stress. Therefore, here is the
                information describing what a hotel cancellation policy is like, how it works, how it pertains to you,
                and for what reason it must always be consulted before clicking "confirm booking."
            </div>
        </div>
        <div id="Cancellation-Policy" class="info-content-crm" fxLayout="column" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">What is a Hotel Cancellation Policy?</h2>
            <div class="info-text">In other words, a hotel reservation cancellation policy is a set of rules addressing
                the possibility of a reservation cancellation, which may or may not be charged depending on the advance
                notice made before the actual booked date. One of the policies should be implemented for other policies
                to work: for any owner of an inventory, such as a hotel or now a B&B, the policy of cancellation is a
                management tool to minimize no-shows. Each time a customer cancels it is a risk for the hotel that there
                will be only disappointment since there is not enough time to occupy that room. Cancellation policies
                are designed in a way that gives guests the freedom they seek while giving hotels some security against
                possible losses. This often includes a clear Hotel refund policy that informs guests how and when they
                can get their money back.
            </div>
        </div>
        <div id="Types-Of-Cancellation-Policy" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Types of Hotel Cancellation Policies</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Flexible Cancellation Policies
                </h3>
                <div class="info-text">Some hotels do implement the use of flexible cancellation policies which helps
                    guests to cancel 24-48 hours before the check-in date without any penalties. This is especially
                    useful for active travellers, whose plans may suddenly change as it gives them the option to adjust
                    their itinerary without added costs.
                </div>
                <div class="demosection">
                    <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Delight Your Guests With AI-Powered Experiences
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Non-Refundable Rates
                </h3>
                <div class="info-text">A non-refundable rate means you should be certain of your travel plans when
                    booking one. Non-refundable bookings have cheaper prices, but such is laissez-faire. In this case,
                    if you cancel, you are going to forfeit the whole amount that you had paid. A budget nonrefundable
                    policy is taken up by some cost saving travelers and risks taking for a cheaper price.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Partially Refundable Policies
                </h3>
                <div class="info-text">Some hotels also allow cancellation of the reservation and offer a partial
                    refund. For example, there may be a 50% refund if a customer cancels after booking within
                    forty-eight hours before the check-in date. Refund of deposits from this window period is forfeited.
                    The intermediate option compared to the non-refundable rates offers a bit more flexibility, but less
                    than fully flexible alternatives.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Same-Day Cancellation Policies
                </h3>
                <div class="info-text">These policies are not the norm, however, they are thoroughly addressed in
                    certain hotel establishments. Travelers can cancel even on the day of arrival usually for a fee or
                    in some cases for free, and this practice is called same-day cancellation. This is regarded as the
                    most liberal form of hotel cancellation policy but remember these are mostly applicable for more
                    upscale hotels or on some occasions only.
                </div>
            </div>
        </div>

        <div id="Key-Terms-In-Cancellation-Policy" fxLayout="column" class="info-content1" fxLayoutGap="2%"
            fxFlex="65%">
            <h2 class="container-head">Key Terms in Hotel Cancellation Policies</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Cancellation Deadline
                </h3>
                <div class="info-text">The cancellation deadline is the date or time before which you must cancel your
                    booking to avoid penalties. This could vary anywhere between 24 hours and a week prior to the time
                    of your stay and this is related to the hotel. Always check this when making a reservation.
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/cancellation deadline.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/cancellation deadline.png">
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Refundable vs. Non-Refundable
                </h3>
                <div class="info-text">Refundable bookings offer more flexibility, allowing you to cancel and get your
                    money back, as long as it’s done within the cancellation window. Non-refundable or non-cancellation
                    booking does not accept cancellation or provides any refunds to the customer when they do cancel.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. No-Show Policies
                </h3>
                <div class="info-text">A no-show policy penalizes guests who do not arrive for their booking without
                    prior notice. You could be charged for the first night or even the full stay depending on the
                    hotel’s terms.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Early Check-Out Penalties
                </h3>
                <div class="info-text">Some hotels have penalties for customers who decide to leave before the initial
                    date that was agreed upon. This is especially so for the customer who is yet to make their plans and
                    is not certain as to when they will leave.
                </div>
                <div class="demosection">
                    <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Delight Your Guests With AI-Powered Experiences
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="Booking-Cancellation-Policy" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head1">The Importance of Having a Clear Booking Cancellation Policy</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div class="info-text">In protecting their revenue, hotels should have a simple and unambiguous booking
                    cancellation policy so that due accountability is emphasized and non-revenue factors minimized. In
                    the absence of these policies, quite a number of last minute cancellations or no show situations may
                    lead to empty available rooms, space which could easily have been occupied by other guests. From the
                    guest’s point of view, an acceptable, easy-to-understand cancellation policy fosters confidence in
                    the hotel’s booking system.Hotels that use customer relationship management (CRM) software and
                    systems including Hotel CRM too can analyze why guests cancel and keep track of their preferences in
                    order to enhance customer satisfaction. This could include helping guests remember cancellation
                    deadlines or placing a few restrictions on charges for guests who’ve established a reliable booking
                    history.
                </div>
            </div>
        </div>
        <div id="Hotel-Refund-Policy" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Hotel Refund Policy Process: What to Expect?</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Timeline for Refunds
                </h3>
                <div class="info-text">If you have canceled a refundable booking, you may be eagerly waiting for the
                    refund to reflect in your account. In most cases, it’s standard practice for a hotel to process a
                    refund within 7-10 business days, though there are others that can take up 30 days depending on your
                    bank or credit card provider.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. How Refunds are Processed (Credit Card, Vouchers, etc.)
                </h3>
                <div class="info-text">Refunds are generally processed using the original of payment method, such as a
                    credit card. For some hotels, instead of issuing a cash refund, vouchers for future use only may be
                    issued. Confirm this while cancelling to avoid any inconveniences in the future.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. What to Do if a Refund is Delayed
                </h3>
                <div class="info-text">If your refund is taking longer than expected, the first step is to contact the
                    hotel directly. Be sure to have your booking information and cancellation confirmation handy. If the
                    hotel is unresponsive or fails to process the refund, you may need to contact your credit card
                    company to dispute the charge.
                </div>
            </div>
        </div>
        <div id="Conclusion" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Conclusion</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/cancellation conclusion.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/cancellation conclusion.png">
                </div>
                <div class="info-text">Looking at the cancellation policies of hotels is not the most entertaining part
                    of booking a trip. Nonetheless, they are very important when it comes to having a no gratifying
                    experience. Always check the Hotel refund policy along with the cancellation rules to ensure you’re
                    aware of your rights. When booking a hotel, why not take a few minutes to familiarize yourself with
                    the conditions as next time it makes sense. A little forethought is essential for the conference to
                    be a success.
                </div>
                <div fxLayout="row" fxLayoutGap="3%" class="social-media">
                    <div>
                        <img src="assets/images/whatsapp.png">
                    </div>
                    <div>
                        <img src="assets/images/linkedin.png">
                    </div>
                    <div>
                        <img src="assets/images/email.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="faq-container" id="faq-section" #faqSection>
    <h2 class="faq-header">Frequently Asked Questions</h2>
    <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
            {{ faq.question }}
            <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
            <p [innerHTML]="faq.answer"></p>
        </div>
    </div>
</div>
<div class="learn-more-contents" fxLayout="column" fxLayoutGap="2%">
    <div class="learn-more-head">
        Not sure how to get started?
    </div>
    <div class="learn-text">
        Join thousands of hoteliers from around the world already leveraging the power of AI, with GoFriday.
    </div>
    <div>
        <button class="book-btn" (click)="openCalendlyPopup()">
            Book a Demo
        </button>
    </div>
</div>
<div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
    <div class="contact-image" fxLayout="column" fxFlex="50%">
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div>
            <img src="assets/images/contact-us.png">
        </div>
    </div>
    <div class="contact-form" fxLayout="column" fxFlex="50%">
        <div class="form-heading">
            Contact Us
        </div>
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                            placeholder="Email" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
    <div class="form-heading">
        Contact Us
    </div>
    <div>
        <p class="single-line">We're always happy to hear from you.</p>
    </div>
    <div style="text-align: center;">
        <img src="assets/images/contact-us.png">
    </div>
    <div class="contact-form" fxLayout="column">
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                            autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<app-footer></app-footer>