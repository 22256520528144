<app-header></app-header>
<div class="plans-main-container" fxLayout="column">
    <div class="plans-container1" fxLayout="row" fxLayoutAlign="center" fxFlex="20%">
        <span class="plans-title">Our flexible plans ensure that you receive the services and solutions that align
            perfectly with your business objectives.</span>
    </div>
    <div class="plans-container2" fxLayout="row" fxLayoutAlign="space-between" *ngIf="!mobile">
        <div fxLayout="column">
            <span class="trial-title">
                Your First 30 Days, Absolutely Free!
            </span>
            <button class="book-btn" (click)="openCalendlyPopup()">
                Book A Demo Today
            </button>
        </div>
        <div class="trial-image">
            <img class="trial-img" src="../../../assets/images/plans_trial.png" />
        </div>
    </div>
    <div class="plans-container2" fxLayout="column" *ngIf="mobile" fxLayoutAlign="start center">
        <div>
            <img class="trial-img" src="../../../assets/images/plans_trial.png" />
        </div>
        <span class="trial-title">
            Your First 30 Days, Absolutely Free!
        </span>
        <button class="book-btn" (click)="openCalendlyPopup()">
            Book A Demo Today
        </button>
    </div>
    <div id="Pricing" class="plans-container3" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="13px"
        *ngIf="!mobile">
        <span class="trial-subtitle1">
            Pay-Per-Transaction Pricing Model for Hotels
        </span>
        <span class="trial-subtitle2">
            Pay Only for Transactions Made Through the App
        </span>
        <!-- <div class="box-container" fxLayout="row" fxLayoutGap="29px">
            <div style="align-self: center;" (click)="scrollLeft(container)">
                <mat-button  class="scroll-btn">
                    <img src="assets/images/chevron-left.png">
                </mat-button>
            </div>
            <div class="plan-box-container" fxLayout="row" fxLayoutGap="29px" #container
                style="justify-content: center;">
                <div class="plan-box" fxLayout="column" fxLayoutGap="18px" fxLayoutAlign="start center">
                    <div class="plan-name">
                        Free
                    </div>
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutGap="20px" *ngFor="let plan of freePlan"
                            fxLayoutAlign="start center">
                            <span class="content"></span>
                            <div>
                                <span class="plan-features">{{plan}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="plan-box" fxLayout="column" fxLayoutGap="18px" fxLayoutAlign="start center">
                    <div class="plan-name">
                        Basic
                    </div>
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutGap="20px" *ngFor="let plan of basicPlan"
                            fxLayoutAlign="start center">
                            <span class="content">✓</span>
                            <div>
                                <span class="plan-features">{{plan}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="plan-box" fxLayout="column" fxLayoutGap="18px" fxLayoutAlign="start center">
                    <div class="plan-name">
                        Professional
                    </div>
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutGap="20px" *ngFor="let plan of professionalPlan"
                            fxLayoutAlign="start center">
                            <span class="content">✓</span>
                            <div>
                                <span class="plan-features">{{plan}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="plan-box" fxLayout="column" fxLayoutGap="18px" fxLayoutAlign="start center">
                    <div class="plan-name">
                        Enterprise
                    </div>
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutGap="20px" *ngFor="let plan of enterprisePlan"
                            fxLayoutAlign="start center">
                            <span class="content">✓</span>
                            <div>
                                <span class="plan-features">{{plan}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div style="align-self: center;">
            <mat-button  class="scroll-btn" (click)="scrollRight(container)">
                <img src="assets/images/chevron-right.png">
            </mat-button>
        </div> 
        </div> -->
    </div>

    <div id="Pricing" class="plans-container3" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="13px"
        *ngIf="mobile">
        <span class="trial-subtitle1">
            Pay-Per-Transaction Pricing Model for Hotels
        </span>
        <span class="trial-subtitle2">
            Pay Only for Transactions Made Through the App
        </span>
        <!-- <div class="plan-box-container" fxLayout="row" fxLayoutGap="29px">
            <div class="plan-box" fxLayout="column" fxLayoutGap="18px" fxLayoutAlign="start center">
                <div class="plan-name">
                    Free
                </div>
                <div fxLayout="column" fxLayoutGap="20px">
                    <div fxLayout="row" fxLayoutGap="20px" *ngFor="let plan of freePlan" fxLayoutAlign="start center">
                        <span class="content">✓</span>
                        <div>
                            <span class="plan-features">{{plan}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plan-box" fxLayout="column" fxLayoutGap="18px" fxLayoutAlign="start center">
                <div class="plan-name">
                    Basic
                </div>
                <div fxLayout="column" fxLayoutGap="20px">
                    <div fxLayout="row" fxLayoutGap="20px" *ngFor="let plan of basicPlan" fxLayoutAlign="start center">
                        <span class="content">✓</span>
                        <div>
                            <span class="plan-features">{{plan}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plan-box" fxLayout="column" fxLayoutGap="18px" fxLayoutAlign="start center">
                <div class="plan-name">
                    Professional
                </div>
                <div fxLayout="column" fxLayoutGap="20px">
                    <div fxLayout="row" fxLayoutGap="20px" *ngFor="let plan of professionalPlan"
                        fxLayoutAlign="start center">
                        <span class="content">✓</span>
                        <div>
                            <span class="plan-features">{{plan}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plan-box" fxLayout="column" fxLayoutGap="18px" fxLayoutAlign="start center">
                <div class="plan-name">
                    Enterprise
                </div>
                <div fxLayout="column" fxLayoutGap="20px">
                    <div fxLayout="row" fxLayoutGap="20px" *ngFor="let plan of enterprisePlan"
                        fxLayoutAlign="start center">
                        <span class="content">✓</span>
                        <div>
                            <span class="plan-features">{{plan}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <div id="Talk-to-Sales" class="plans-container4" fxLayout="column" fxLayoutAlign="start center" *ngIf="!mobile">
        <span class="quote-header">
            Prices starting as low as $ 5
        </span>
        <span class="quote">
            Get in touch with our sales representatives for a customized quote featuring exclusive discounts and special
            offers</span>
        <button class="book-btn" (click)="selectFreeTrial()">
            Talk to Sales
        </button>
    </div>
    <div id="Talk-to-Sales" class="plans-container4" fxLayoutAlign="start center" fxLayout="column" *ngIf="mobile">
        <span class="quote-header">
            Prices starting as low as $ 5
        </span>
        <span class="quote">
            Get in touch with our sales representatives for a customized quote featuring exclusive discounts and special
            offers
        </span>
        <button class="book-btn" (click)="selectFreeTrial()">
            Talk to Sales
        </button>
    </div>
    <div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
        <div class="contact-image" fxLayout="column" fxFlex="50%">
            <div>
                <p class="single-line">We're always happy to hear from you.</p>
            </div>
            <div>
                <img src="assets/images/contact-us.png">
            </div>
        </div>
        <div class="contact-form" fxLayout="column" fxFlex="50%">
            <div class="form-heading">
                Contact Us
            </div>
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                                placeholder="Email" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
    <div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
        <div class="form-heading">
            Contact Us
        </div>
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div style="text-align: center;">
            <img src="assets/images/contact-us.png">
        </div>
        <div class="contact-form" fxLayout="column">
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                                autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>