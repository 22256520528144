<app-header></app-header>
<!-- <mat-card [ngClass]="{trial_free_container_web : !mobile , trial_free_container_mob : mobile}"> -->
<div class="trial-container">
    <!-- <div *ngIf="!mobile"> -->
    <!-- <img src="assets/images/Rectangle1.png" class="card-image" alt="Background Image"> -->
    <div class="card-text" fxLayout="column" fxLayoutGap="2%">
        <div class="hero-banner-child">
            <span class="free-trial-header"> Integrate, Streamline & Increase Revenue With GoFriday </span>
            <!-- <img src="assets/images/cuate.png" /> -->
        </div>
        <div>
            <div fxLayout="row" fxLayoutGap="2%">
                <div><span class="content">✓</span></div>
                <div class="info-desc"> Boost your hotel’s profitability </div>
            </div>
            <div fxLayout="row" fxLayoutGap="2%">
                <div><span class="content">✓</span></div>
                <div class="info-desc"> Maximize your team’s performance </div>
            </div>
            <div fxLayout="row" fxLayoutGap="2%">
                <div><span class="content">✓</span></div>
                <div class="info-desc"> Enhance guest satisfaction leading to increase in loyalty </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
    <!-- <div *ngIf="mobile"> -->
    <!-- <img src="assets/mobile-images/Rectangle1.png" class="card-image" alt="Background Image" *ngIf="mobile"> -->
    <!-- <div class="card-text1"> -->
    <!-- <div class="hero-banner-child1">
            <img src="assets/mobile-images/Group1.png" />
        </div> -->
    <!-- <div class="hero-banner-child">
            <span class="free-trial-header"> Integrate, streamline, & Increase Revenue With GoFriday </span> -->
    <!-- <img src="assets/images/cuate.png" /> -->
    <!-- <div> -->
    <!-- <form [formGroup]="freeTrialForm" *ngIf="trial_details"
            [ngClass]="{trial_form_details : !mobile , trial_form_details_mob : mobile}">
           
            <div *ngIf="!mobile">
                <span class="trial-header">TRY GOFRIDAY FOR 30-DAYS</span>
            </div>
            <div class="form-labels">
                <div fxLayout="column" fxLayoutGap="0%" class="input-container">
                    <label class="label-input">First Name</label>
                    <div fxLayoutAlign="center center">
                        <input class="theme-input-text" type="text" placeholder="Enter First Name" name="firstName"
                            autocomplete="off" formControlName="firstName" required />
                    </div>
                    <div class="validation-error"
                        *ngIf=" freeTrialForm.get('firstName')?.invalid && freeTrialForm.get('firstName')?.touched && freeTrialForm.controls['firstName']?.errors?.['required']">
                        Please provide a valid name</div>
                    <div class="validation-error"
                        *ngIf="freeTrialForm.get('firstName')?.hasError('pattern') || freeTrialForm.get('firstName')?.hasError('minlength') || freeTrialForm.get('firstName')?.hasError('maxlength')">
                        Min 3 & max length 25 , alphabets only
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="0%" class="input-container">
                    <label class="label-input">Last Name</label>
                    <div fxLayoutAlign="center center">
                        <input class="theme-input-text" type="text" placeholder="Enter Last Name" name="LastName"
                            autocomplete="off" formControlName="lastName" required />
                    </div>
                    <div class="validation-error"
                        *ngIf="freeTrialForm.get('lastName')?.touched && freeTrialForm.controls['lastName']?.errors?.['required']">
                        Please provide a valid name</div>
                    <div class="validation-error"
                        *ngIf="freeTrialForm.get('lastName')?.hasError('pattern') || freeTrialForm.get('lastName')?.hasError('minlength') || freeTrialForm.get('lastName')?.hasError('maxlength')">
                        Min 3 & max length 25 , alphabets only
                    </div>
                </div>
            </div>
            <div class="form-labels">
                <div fxLayout="column" fxLayoutGap="0%" class="input-container">
                    <label class="label-input">Name of the Hotel</label>
                    <div fxLayoutAlign="center center">
                        <input class="theme-input-text" type="text" placeholder="Enter Hotel Name" name="hotelName"
                            autocomplete="off" formControlName="hotelName" required />
                    </div>
                    <div class="validation-error"
                        *ngIf="freeTrialForm.get('hotelName')?.touched && freeTrialForm.controls['hotelName']?.errors?.['required']">
                        Please provide a valid Hotel name</div>
                </div>
                <div fxLayout="column" fxLayoutGap="0%" class="input-container">
                    <label class="label-input">Number of Rooms</label>
                    <div fxLayoutAlign="center center">
                        <input class="theme-input-text" type="text" placeholder="Enter Rooms" name="rooms"
                            autocomplete="off" formControlName="rooms" required />
                    </div>
                    <div class="validation-error"
                        *ngIf="freeTrialForm.get('rooms')?.touched && freeTrialForm.controls['rooms']?.errors?.['required']">
                        Please provide valid no. of Rooms</div>
                    <div class="validation-error"
                        *ngIf="freeTrialForm.get('rooms')?.hasError('pattern') || freeTrialForm.get('rooms')?.hasError('minlength') || freeTrialForm.get('rooms')?.hasError('maxlength')">
                        No. of rooms min 1 & should not exceed 4 digits
                    </div>
                </div>
            </div>
            <div class="form-labels">
                <div fxLayout="column" fxLayoutGap="0%" class="input-container">
                    <label class="label-input">City</label>
                    <div fxLayoutAlign="center center">
                        <input class="theme-input-text" type="text" placeholder="Enter City" name="city"
                            autocomplete="off" formControlName="city" required />
                    </div>
                    <div class="validation-error"
                        *ngIf="freeTrialForm.get('city')?.touched && freeTrialForm.controls['city']?.errors?.['required']">
                        Please
                        provide a valid City name</div>
                </div>
                <div fxLayout="column" fxLayoutGap="0%" class="input-container">
                    <label class="label-input">Country</label>
                    <mat-select formControlName="countryName" class="theme-input-select" name="country"
                        placeholder="Enter country">
                        <mat-option *ngFor="let country of countryList" [value]="country.ID">
                            {{country.NAME}}
                        </mat-option>
                    </mat-select>
                    <div class="validation-error"
                        *ngIf="freeTrialForm.get('countryName')?.touched && freeTrialForm.controls['countryName']?.errors?.['required']">
                        Country is required</div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="0%">
                <label class="label-input">Address</label>
                <div class="input-container-address">
                    <input class="theme-input-address" type="text" placeholder="Enter Address" name="address"
                        autocomplete="off" formControlName="address" required />
                </div>
                <div class="validation-error"
                    *ngIf="freeTrialForm.get('address')?.touched && freeTrialForm.controls['address']?.errors?.['required']">
                    Please provide a valid Address</div>
            </div>
            <div fxLayoutAlign="end center" *ngIf="!mobile">
                <button class="book-btn" [disabled]="freeTrialForm.invalid"
                    (click)="showHideTrialConfirmation()">Next</button>
            </div>
            <div fxLayoutAlign="start" *ngIf="mobile">
                <button class="book-btn" [disabled]="freeTrialForm.invalid"
                    (click)="showHideTrialDetails()">Next</button>
            </div>
           
        </form> -->
    <!-- </div> -->
    <form [formGroup]="trialEmailForm" *ngIf="!trial_details"
        [ngClass]="{trial_form_details : !mobile , trial_form_details_mob : mobile}">
        <!-- <div class="trial-container-emaildetails" fxLayout="column" fxLayoutGap="5%"> -->
        <div>
            <span class="trial-header">Let's start a conversation</span>
        </div>
        <div fxLayout="column" fxLayoutGap="0%" class="label-container">
            <label class="label-input">Name of the Hotel <iconify-icon icon="bi:asterisk"
                    class="asterisk-icon"></iconify-icon></label>
            <div class="input-container-address">
                <input class="theme-input-address" type="text" placeholder="Enter Hotel Name" name="hotelName"
                    formControlName="hotelName" maxlength="200" autocomplete="off" required />
            </div>
            <div class="validation-error"
                *ngIf="trialEmailForm.get('hotelName')?.touched && trialEmailForm.controls['hotelName']?.errors?.['required']">
                Please provide a valid Hotel name</div>
        </div>
        <div fxLayout="column" fxLayoutGap="0%" class="label-container">
            <label class="label-input">Email <iconify-icon icon="bi:asterisk"
                    class="asterisk-icon"></iconify-icon></label>
            <div class="input-container-address">
                <input class="theme-input-email" type="text" maxlength="200" placeholder="Enter Email" name="email"
                    autocomplete="off" formControlName="email" required />
            </div>
            <div class="validation-error"
                *ngIf="trialEmailForm.get('email')?.touched && trialEmailForm.controls['email']?.errors?.['required']">
                Please
                provide a valid Email ID</div>
            <div class="validation-error" *ngIf="trialEmailForm.get('email')?.errors?.['pattern']">
                Enter Valid Email
            </div>
        </div>
        <!-- <div fxLayout="column" fxLayoutGap="0%" class="label-container">
                <label class="label-input">Country</label>
                <mat-select formControlName="countryName" class="theme-input-address" name="country"
                    placeholder="Enter country" (selectionChange)="changeCountry($event.value)">
                    <mat-select-trigger>{{selectedCountry}}</mat-select-trigger>
                    <mat-option *ngFor="let country of countryList" [value]="country.ID">
                        <div fxLayout="row">
                            <div class="c-code">+{{country.CODE}}</div>
                            <div class="c-name">{{country.NAME}}</div>
                        </div>
                    </mat-option>
                </mat-select>
                <div class="validation-error"
                    *ngIf="trialEmailForm.get('countryName')?.touched && trialEmailForm.controls['countryName']?.errors?.['required']">
                    Country is required</div>
            </div> -->
        <div fxLayout="column" fxLayoutGap="0%" class="label-container">
            <label class="label-input">Contact Number</label>
            <div class="input-container-address-phno">
                <!-- <input class="theme-input-phcode" [readonly]="true" formControlName="phoneCode"
                        [(value)]="countryCode"> -->
                <mat-select panelClass="codeClass" class="theme-input-phcode" formControlName="phoneCode" name="country"
                    placeholder="Select" (selectionChange)="changeCountry($event.value)">
                    <mat-select-trigger>{{countryCode}}</mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" [formControl]="country"
                            noEntriesFoundLabel="No Matching Found">
                            <mat-icon ngxMatSelectSearchClear class="close-icon">close</mat-icon>
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let country of filterList" [value]="country.ID">
                        <div fxLayout="row" class="options">
                            <div class="c-code">+{{country.CODE}}</div>
                            <div class="c-name">{{country.NAME}}</div>
                        </div>
                    </mat-option>
                </mat-select>
                <input class="theme-input-num" type="text" placeholder="Enter Contact Number" name="number"
                    autocomplete="off" formControlName="phoneNumber" (input)="getInput($event)">
            </div>
            <!-- <div class="validation-error"
                    *ngIf="trialEmailForm.get('phoneNumber')?.touched && trialEmailForm.controls['phoneNumber']?.errors?.['required']">
                    Please provide a valid Contact Number</div> -->
            <div class="validation-error" *ngIf="trialEmailForm.get('phoneCode')?.hasError('required')">
                Country Code is required
            </div>
            <div class="validation-error" *ngIf="trialEmailForm.get('phoneNumber')?.hasError('pattern')">
                Phone number must contain only numbers and shouldn't exceed 50 digits
            </div>
            <div class="validation-error" *ngIf="trialEmailForm.get('phoneNumber')?.hasError('required')">
                Phone number is required
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="0%" class="label-container">
            <div fxLayout="row">
                <input type="checkbox" class="check-box" formControlName="tnc" />
                <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                        (click)="viewPrivacyPolicy()">"Privacy
                        Policy"</a>
                    and <br *ngIf="!mobile">
                    <a class="privacy-link" (click)="viewTermsConditions()">"Terms and Conditions"</a></span>
            </div>
            <div fxLayout="row" fxLayoutGap="37%" *ngIf="!mobile" fxLayoutAlign="center">
                <!-- <div>
                        <button class="back-btn" (click)="back()">Back</button>
                    </div> -->
                <div>
                    <button class="get-started-btn" (click)="startFreeTrial()" type="submit"
                        [disabled]="trialEmailForm.invalid || trialEmailForm.get('tnc')?.value == false">
                        Get Started
                        <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                    </button>
                </div>
            </div>
            <div fxLayout="row" *ngIf="mobile" fxLayoutAlign="center">
                <!-- <div>
                        <button class="back-btn" (click)="back()">Back</button>
                    </div> -->
                <div>
                    <button class="get-started-btn" (click)="startFreeTrial()" type="submit"
                        [disabled]="trialEmailForm.invalid || trialEmailForm.get('tnc')?.value == false">
                        Get Started
                        <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- </mat-card> -->