import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { SectionsComponent } from './sections/sections.component';
import { HeaderComponent } from './sections/header/header.component';
import { FooterComponent } from './sections/footer/footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { FreeTrialComponent } from './pages/free-trial/free-trial.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HttpClientModule } from '@angular/common/http';
import { PopupComponent } from './pages/popup/popup.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { PlansComponent } from './pages/plans/plans.component';
import { FeaturesPageComponent } from './pages/features-page/features-page.component';
import { NgChartsModule } from 'ng2-charts';
import { PartnerProgramComponent } from './pages/partner-program/partner-program.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { ExperienceDetailComponent } from './pages/experience-detail/experience-detail.component';
import { ReserveComponent } from './pages/reserve/reserve.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ScrollTopComponent } from './pages/scroll-top/scroll-top.component';
import { GuestAppComponent } from './pages/guest-app/guest-app.component';
import { ClioComponent } from './pages/clio/clio.component';
import { TaskAppComponent } from './pages/task-app/task-app.component';
import { CalendlyComponent } from './pages/calendly/calendly.component';
import { Blog1Component } from './pages/blog1/blog1.component';
import { Blog2Component } from './pages/blog2/blog2.component';
import { Blog3Component } from './pages/blog3/blog3.component';
import { Blog4Component } from './pages/blog4/blog4.component';
import { Blog5Component } from './pages/blog5/blog5.component';
import { Blog6Component } from './pages/blog6/blog6.component';
import { Blog7Component } from './pages/blog7/blog7.component';
import { Blog8Component } from './pages/blog8/blog8.component';
import { Blog9Component } from './pages/blog9/blog9.component';
import { Blog10Component } from './pages/blog10/blog10.component';
import { Blog11Component } from './pages/blog11/blog11.component';
import { Blog12Component } from './pages/blog12/blog12.component';
import { Blog13Component } from './pages/blog13/blog13.component';




@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    PagesComponent,
    HomePageComponent,
    SectionsComponent,
    HeaderComponent,
    FooterComponent,
    FreeTrialComponent,
    AboutUsComponent,
    PopupComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    BlogPageComponent,
    PlansComponent,
    FeaturesPageComponent,
    PartnerProgramComponent,
    ExperienceComponent,
    ExperienceDetailComponent,
    ReserveComponent,
    ContactUsComponent,
    ScrollTopComponent,
    GuestAppComponent,
    ClioComponent,
    TaskAppComponent,
    CalendlyComponent,
    Blog1Component,
    Blog2Component,
    Blog3Component,
    Blog4Component,
    Blog5Component,
    Blog6Component,
    Blog7Component,
    Blog8Component,
    Blog9Component,
    Blog10Component,
    Blog11Component,
    Blog12Component,
    Blog13Component,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    MaterialModule,
    NgChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }