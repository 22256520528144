import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { colorObj } from 'src/shared/color-object';
import { PopupComponent } from '../popup/popup.component';
declare const Calendly: any;

@Component({
  selector: 'app-blog13',
  templateUrl: './blog13.component.html',
  styleUrls: ['./blog13.component.css']
})
export class Blog13Component {
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;
  @ViewChild('faqSection') faqSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  faqs = [
    {
      question: 'Do I need a degree to start a travel agency?',
      answer: 'Not necessarily! While a degree can be beneficial, especially in fields like hospitality or business, what’s more important is your knowledge of the industry and a passion for travel.',
      open: false
    },
    {
      question: 'How much money do I need to start a travel agency?',
      answer: 'Startup costs can vary widely depending on your business model. A home-based travel agency can be started with a modest budget, while a physical location may require more investment.',
      open: false
    },
    {
      question: 'What are some common challenges in starting a travel agency?',
      answer: 'Challenges can include competition, managing client expectations, and staying updated on industry trends. Building a strong brand and client base is essential to overcoming these hurdles.',
      open: false
    },
    {
      question: 'What licenses do I need to operate a travel agency?',
      answer: 'Licensing requirements differ by location. Typically, you’ll need a business license and may require additional certifications depending on your area. Always check local regulations to ensure compliance.',
      open: false
    }
  ];

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const faqSectionTop = this.faqSectionRef.nativeElement.offsetTop;
    const threshold2 = 530;
    if (!this.isPopupOpen) {
      this.showStickyOverview = scrollPosition < (faqSectionTop - threshold2);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'Travel-Industry',
      'Define-Your-Niche',
      'Business-Plan',
      'Legal-Requirements',
      'Set-Up-Business',
      'Relationships-With-Suppliers',
      'Marketing-Travel-Agency',
      'Customer-Service',
      'Stay-Informed-And-Adapt',
      'Conclusion'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  viewLinkedIn() {
    const linkedInUrl = environment.LINKEDIN_URL;
    const websiteUrl = `${environment.WEBSITE_URL}/blog/how-to-start-a-travel-agency`;
    const fullUrl = `${linkedInUrl}${websiteUrl}`;
    window.open(fullUrl, "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    this.disabled = true;
    this.isPopupOpen = true;
    let obj = {
      "NAME": this.contactUsForm.value.name,
      "EMAIL": this.contactUsForm.value.email,
      "MESSAGE": this.contactUsForm.value.message,
      "TNC": true
    }
    this.hotelService.contactUs(obj).subscribe({
      next: (data) => {
        this.disabled = false;
        if (!!data?.object?.ID) {
          const dialogRef = this.dialog.open(PopupComponent, {
            width: '50%',
            data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(() => {
            this.isPopupOpen = false;
          });
        } else {
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        }
        this.contactUsForm.reset();
      },
      error: (e) => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewTravelIndustry() {
    this.scrollToSection('Travel-Industry');
  }

  viewDefineYourNiche() {
    this.scrollToSection('Define-Your-Niche');
  }

  viewBusinessPlan() {
    this.scrollToSection('Business-Plan');
  }

  viewLegalRequirements() {
    this.scrollToSection('Legal-Requirements');
  }

  viewSetUpBusiness() {
    this.scrollToSection('Set-Up-Business');
  }

  viewRelationshipsWithSuppliers() {
    this.scrollToSection('Relationships-With-Suppliers');
  }

  viewMarketingTravelAgency() {
    this.scrollToSection('Marketing-Travel-Agency');
  }

  viewCustomerService() {
    this.scrollToSection('Customer-Service');
  }

  viewStayInformedAndAdapt() {
    this.scrollToSection('Stay-Informed-And-Adapt');
  }

  viewConclusion() {
    this.scrollToSection('Conclusion');
  }


  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}
