<app-header></app-header>
<div class="container">
    <div fxLayout="column" class="programContainer width100" fxLayoutGap="10px" *ngIf="!mobile">
        <div class="program-head">
            Enjoy Our <span class="gofryday">GoFriday </span>Partner Program
        </div>
        <div class="program-desc">
            At GoFriday, we believe in the power of collaboration and building strong partnerships.
            Our program is designed to create meaningful partnerships with organizations passionate about transforming
            the hospitality industry.
        </div>
    </div>
    <div fxLayout="column" class="programContainer width100" fxLayoutGap="10px" *ngIf="mobile">
        <div class="program-head">
            Enjoy Our <span class="gofryday">GoFriday </span>Partner Program
        </div>
        <div class="program-desc">
            At GoFriday, we believe in the power of collaboration and building strong partnerships.
            Our program is designed to create meaningful partnerships with organizations passionate about transforming
            the hospitality industry.
        </div>
    </div>
    <div class="programContainer1" fxLayout="row" fxLayoutGap="10px" *ngIf="!mobile">
        <div fxLayout="column" fxLayoutGap="23px">
            <div class="program-head1">
                Why choose <span class="gofryday">GoFriday</span>?
            </div>
            <div class="content-head" fxLayout="row">
                <div fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
                        <span class="content"></span>
                        <div class="content-desc">Unlock Profitable Revenue Streams </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
                        <span class="content"></span>
                        <div class="content-desc"> Expand Your Reach And Network </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
                        <span class="content"></span>
                        <div class="content-desc">Custom Tailored Partnership Options </div>
                    </div>
                    <button class="contact-btn" (click)="selectContact()">
                        Contact Us
                    </button>
                </div>
            </div>
        </div>
        <div>
            <img src="/assets/images/OBJECTS.png" alt="" />
        </div>
    </div>
    <div class="programContainer1" fxLayout="column" fxLayoutGap="10px" *ngIf="mobile">
        <div fxLayout="column" fxLayoutGap="23px">
            <div class="program-head1">
                Why choose <span class="gofryday">GoFriday</span>?
            </div>
            <div>
                <img class="obj-img" src="/assets/images/OBJECTS.png" alt="" />
            </div>
            <div class="content-head" fxLayout="column">
                <div fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
                        <span class="content"></span>
                        <div class="content-desc">Unlock Profitable Revenue Streams </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
                        <span class="content"></span>
                        <div class="content-desc"> Expand Your Reach And Network </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
                        <span class="content"></span>
                        <div class="content-desc">Custom Tailored Partnership Options </div>
                    </div>
                </div>
                <button class="contact-btn" (click)="selectContact()">
                    Contact Us
                </button>
            </div>
        </div>
    </div>
    <div class="program-offers" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">
        <div class="program-offers-head">
            Our Partnership Programs
        </div>
        <div fxLayout="row" fxLayoutGap="5%" *ngIf="!mobile">
            <mat-card class="card">
                <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="center center">

                    <img src="assets/images/Reseller1.png">

                    <div fxLayout="column" fxLayoutGap="5%">
                        <div class="partnership-header">
                            Reseller Partnership
                        </div>
                        <div class="partnership-content">Earn upto 20% Commission</div>

                    </div>
                </div>
            </mat-card>
            <mat-card class="card">
                <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="center center">

                    <img src="assets/images/Referral.png">

                    <div fxLayout="column" fxLayoutGap="5%">
                        <div class="partnership-header">
                            Referral Partnership
                        </div>
                        <div class="partnership-content">Earn upto 10% Commission</div>

                    </div>
                </div>
            </mat-card>
        </div>

        <div fxLayout="column" fxLayoutGap="5%" *ngIf="mobile">
            <mat-card class="card">
                <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="center center">

                    <img src="assets/images/Reseller1.png">

                    <div fxLayout="column" fxLayoutGap="5%">
                        <div class="partnership-header">
                            Reseller Partnership
                        </div>
                        <div class="partnership-content">Earn upto 20% Commission</div>

                    </div>
                </div>
            </mat-card>
            <mat-card class="card">
                <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="center center">

                    <img src="assets/images/Referral.png">

                    <div fxLayout="column" fxLayoutGap="5%">
                        <div class="partnership-header">
                            Referral Partnership
                        </div>
                        <div class="partnership-content">Earn upto 10% Commission</div>

                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="program-offers" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px" *ngIf="!mobile">
        <div class="program-offers-head">
            What we offer
        </div>
        <div class="program-offers-content">
            <div class="program-offer" fxLayout="column" fxLayoutAlign="center center" fxFlex="26%">
                <img class="program-offer-image" src="/assets/images/Rates.png" alt="" />
                <div class="program-offer-content-head">
                    Competitive Commission Rates
                </div>
                <div class="program-offer-content">Enjoy attractive commission rates that outshine industry standards,
                    ensuring you maximize your earnings. </div>
            </div>
            <div class="program-offer" fxLayout="column" fxLayoutAlign="center center" fxFlex="26%">
                <img class="program-offer-image" src="/assets/images/training.png" alt="" />
                <div class="program-offer-content-head">
                    Comprehensive Training
                </div>
                <div class="program-offer-content">We equip you with the knowledge and resources to effectively promote
                    GoFriday.</div>
            </div>
            <div class="program-offer" fxLayout="column" fxLayoutAlign="center center" fxFlex="26%">
                <img class="program-offer-image" src="/assets/images/support.png" alt="" />
                <div class="program-offer-content-head">
                    Priority Support
                </div>
                <div class="program-offer-content">Receive prompt assistance and guidance from our dedicated partner
                    success team. </div>
            </div>
            <div class="program-offer" fxLayout="column" fxLayoutAlign="center center" fxFlex="26%">
                <img class="program-offer-image" src="/assets/images/initiatives.png" alt="" />
                <div class="program-offer-content-head">
                    Joint Marketing Initiatives
                </div>
                <div class="program-offer-content">Collaborate with us on co-branded marketing campaigns to maximize
                    reach and impact.  </div>
            </div>
            <div class="program-offer" fxLayout="column" fxLayoutAlign="center center" fxFlex="26%">
                <img class="program-offer-image" src="/assets/images/portal.png" alt="" />
                <div class="program-offer-content-head">
                    Dedicated Partner Portal
                </div>
                <div class="program-offer-content">Access marketing materials, sales tools, and real-time insights into
                    your performance and revenue. </div>
            </div>
        </div>
    </div>
    <div class="program-offers" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="mobile">
        <div class="program-offers-head">
            What we offer
        </div>
        <div class="program-offers-content">
            <div class="program-offer" fxLayout="column" fxLayoutAlign="center center" fxFlex="24%">
                <img class="program-offer-image" src="/assets/images/Rates.png" alt="" />
                <div class="program-offer-content-head">
                    Competitive Commission Rates
                </div>
                <div class="program-offer-content">Enjoy attractive commission rates that outshine industry standards,
                    ensuring you maximize your earnings. </div>
            </div>

            <div class="program-offer" fxLayout="column" fxLayoutAlign="center center" fxFlex="24%">
                <img class="program-offer-image" src="/assets/images/training.png" alt="" />
                <div class="program-offer-content-head">
                    Comprehensive Training
                </div>
                <div class="program-offer-content">We equip you with the knowledge and resources to effectively promote
                    GoFriday.</div>
            </div>
            <div class="program-offer" fxLayout="column" fxLayoutAlign="center center" fxFlex="24%">
                <img class="program-offer-image" src="/assets/images/support.png" alt="" />
                <div class="program-offer-content-head">
                    Priority Support
                </div>
                <div class="program-offer-content">Receive prompt assistance and guidance from our dedicated partner
                    success team. </div>
            </div>
            <div class="program-offer" fxLayout="column" fxLayoutAlign="center center" fxFlex="24%">
                <img class="program-offer-image" src="/assets/images/initiatives.png" alt="" />
                <div class="program-offer-content-head">
                    Joint Marketing Initiatives
                </div>
                <div class="program-offer-content">Collaborate with us on co-branded marketing campaigns to maximize
                    reach and impact.  </div>
            </div>
            <div class="program-offer" fxLayout="column" fxLayoutAlign="center center" fxFlex="24%">
                <img class="program-offer-image" src="/assets/images/portal.png" alt="" />
                <div class="program-offer-content-head">
                    Dedicated Partner Portal
                </div>
                <div class="program-offer-content">Access marketing materials, sales tools, and real-time insights into
                    your performance and revenue. </div>
            </div>
        </div>
    </div>
    <div class="partner-program-works" fxLayoutGap="80px" fxLayoutAlign="center center" fxLayout="column"
        *ngIf="!mobile">
        <div class="partner-program-works-head">
            How Our Program Works?
        </div>
        <div class="partner-program-works-details" fxLayout="column">
            <div class="partner-program-works-info" fxLayout="row">
                <div class="content-box">
                    <div class="content-no">1</div>
                    <div class="content-title">Verbal Agreement</div>
                    <div class="content-info">Both sides agree verbally to build a partnership </div>
                </div>
                <div style="align-self: center;">
                    <img src="/assets/images/vector1.png" alt="" />
                </div>
                <div class="content-box-even-row1">
                    <div class="content-no">2</div>
                    <div class="content-title2">Apply for Partnership</div>
                    <div class="content-info">Submit your application to join the Partner Program. Share your business
                        details, expertise, and why you want to partner with us.   </div>
                </div>
            </div>
            <div class="partner-program-works-info" fxLayout="row">

                <div class="content-box">
                    <div class="content-no">3</div>
                    <div class="content-title">Application Review and Approval</div>
                    <div class="content-info">Once you apply, our team reviews your submission. If it meets our
                        criteria, you become a GoFriday Partner. </div>
                </div>
                <div>
                    <img src="/assets/images/vector2.png" alt="" />
                </div>
                <div class="left-vector1">
                    <img src="/assets/images/vector1.png" alt="" />
                </div>
                <div class="content-box-even-row2">
                    <div class="content-no">4</div>
                    <div class="content-title2">Access Partner Resources</div>
                    <div class="content-info">After approval, access exclusive partner resources. training, marketing
                        materials, and co-branded content. Empower yourself to effectively promote and integrate
                        GoFriday.   
                    </div>
                </div>
            </div>
            <div class="partner-program-works-info" fxLayout="row">
                <div class="content-box">
                    <div class="content-no">5</div>
                    <div class="content-title">Promote GoFriday</div>
                    <div class="content-info">Promote us in your network—whether you're a consultant or a reseller.
                        Highlight benefits and leverage our marketing materials to drive interest in our solutions.
                    </div>
                </div>
                <div>
                    <img src="/assets/images/vector2.png" alt="" />
                </div>
                <div class="left-vector1">
                    <img src="/assets/images/vector1.png" alt="" />
                </div>
                <div class="content-box-even-row2">
                    <div class="content-no">6</div>
                    <div class="content-title2">Earn Commissions </div>
                    <div class="content-info">For every successful referral or sale, earn commissions and rewards.
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="partner-program-works" fxLayoutGap="40px" fxLayoutAlign="center center" fxLayout="column"
        *ngIf="mobile">
        <div class="partner-program-works-head">
            How Our Program Works?
        </div>
        <div class="partner-program-works-details" fxLayout="column">
            <!-- <div class="partner-program-works-info" fxLayout="row"> -->
            <div class="content-box">
                <div class="content-no">1</div>
                <div class="content-title">Verbal Agreement</div>
                <div class="content-info">Both sides agree verbally to build a partnership </div>
                <!-- <div class="left-vector">
                    <img src="/assets/images/vector1.png" class="vector-img" alt="" />
                </div> -->
            </div>
            <div class="content-box">
                <div class="content-no">2</div>
                <div class="content-title">Apply for Partnership</div>
                <div class="content-info">Submit your application to join the Partner Program. Share your business
                    details, expertise, and why you want to partner with us.  </div>
                <!-- <div class="right-vector">
                    <img src="/assets/images/vector2.png" class="vector-img" alt="" />
                </div> -->
            </div>

            <!-- <div class="partner-program-works-info" fxLayout="row"> -->
            <div class="content-box">
                <div class="content-no">3</div>
                <div class="content-title">Application Review and Approval</div>
                <div class="content-info">Once you apply, our team reviews your submission. If it meets our criteria,
                    you become a GoFriday Partner.</div>
                <!-- <div class="left-vector">
                    <img src="/assets/images/vector1.png" class="vector-img" alt="" />
                </div> -->
            </div>
            <div class="content-box">
                <div class="content-no">4</div>
                <div class="content-title">Access Partner Resources</div>
                <div class="content-info">After approval, access exclusive partner resources. training, marketing
                    materials, and co-branded content. Empower yourself to effectively promote and integrate
                    GoFriday.
                </div>
                <!-- <div class="right-vector">
                    <img src="/assets/images/vector2.png" class="vector-img" alt="" />
                </div> -->
            </div>
            <!-- </div> -->
            <!-- <div class="partner-program-works-info-last" fxLayout="row"> -->
            <div class="content-box">
                <div class="content-no">5</div>
                <div class="content-title">Promote GoFriday</div>
                <div class="content-info">Promote us in your network—whether you're a consultant or a reseller.
                    Highlight benefits and leverage our marketing materials to drive interest in our solutions.
                </div>
                <!-- <div class="left-vector">
                    <img src="/assets/images/vector1.png" class="vector-img" alt="" />
                </div> -->
            </div>
            <div class="content-box">
                <div class="content-no">6</div>
                <div class="content-title">Earn Commissions </div>
                <div class="content-info">For every successful referral or sale, earn commissions and rewards. 
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>


    <div class="application-details">
        <div class="left-part" fxLayout="column" fxLayoutGap="7px">
            <div class="application-head">
                Apply Today
            </div>
            <div fxLayout="row" *ngIf="!mobile" fxLayoutAlign="space-between">
                <div class="application-info">
                    A successful partnership starts with a <br>conversation. Let's talk!
                </div>
                <div class="right-part">
                    <input type="text" class="apply-box" [formControl]="email" placeholder="Type Your Email">
                    <button class="apply-btn" (click)="connect()"
                        [disabled]="email.value == '' || email.hasError('pattern') || disabled">Get In Touch
                        <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i></button>
                    <div>
                        <mat-error class="mat-error" *ngIf="!mobile && email.hasError('pattern')">Please provide a valid
                            Email
                            Id</mat-error>
                    </div>
                </div>
            </div>
            <div class="application-info" *ngIf="mobile">
                A successful partnership starts with a conversation. Let's talk!
            </div>
        </div>
        <div class="right-part" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center" *ngIf="mobile">
            <input type="text" class="apply-box" [formControl]="email" placeholder="Type Your Email">
            <mat-error class="mat-error" *ngIf="email.hasError('pattern')">Please provide a valid Email
                Id</mat-error>
            <button class="apply-btn" (click)="connect()"
                [disabled]="email.value == '' || email.hasError('pattern') || disabled"><i class="fa fa-spinner fa-spin"
                    *ngIf="disabled"></i> Get In Touch</button>
        </div>
    </div>

    <div id="contactFormSection" fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
        <div class="contact-image" fxLayout="column" fxFlex="50%">
            <div>
                <p class="single-line">We're always happy to hear from you.</p>
            </div>
            <div>
                <img src="assets/images/contact-us.png">
            </div>
        </div>
        <div class="contact-form" fxLayout="column" fxFlex="50%">
            <div class="form-heading">
                Contact Us
            </div>
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                                placeholder="Email" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
    <div id="contactFormSection" fxLayout="column" class="contactContainer width100" *ngIf="mobile">
        <div class="form-heading">
            Contact Us
        </div>
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div style="text-align: center;">
            <img src="assets/images/contact-us.png">
        </div>
        <div class="contact-form" fxLayout="column">
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                                autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>